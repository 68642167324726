import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { getDataFromQueryString, setDataInQueryString } from './url';
import { evaluate, format } from 'mathjs';
import { makeStyles } from '@material-ui/core/styles';
import { nanoid } from 'nanoid';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import grey from '@material-ui/core/colors/grey';

const styleReset = {
  border: 'none',
  margin: 0,
  padding: 0,
  fontFamily: 'inherit',
  fontSize: 'inherit',
};

const lineHeight = '2';

const styleFont = {
  fontSize: '1rem',
  lineHeight,
  fontFamily: 'Roboto Mono',
};

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    ...styleFont,
    padding: '1rem',
  },
  editor: {
    width: '60%',
    margin: '2rem',
    '& .ql-container': styleReset,
    '& .ql-editor': styleReset,
    '& p': {
      lineHeight,
      ...styleReset,
    },
  },
  results: {
    padding: '2rem',
    textAlign: 'right',
    borderLeft: '1px solid',
    borderColor: grey[300],
  },
  footer: {
    margin: '2rem 1rem',
    textAlign: 'right',
  },
});

const splitToLines = inputText => {
  const lines = inputText.split('\n').filter(line => line || line === '');
  if (lines[lines.length - 1] === '') return lines.slice(0, -1);
  return lines;
};

const App = () => {
  const classes = useStyles();
  const [addedFromUrl, setAddedFromUrl] = useState(false);
  const [lines, setLines] = useState([]);
  const [variables, setVariables] = useState({});

  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: false,
    },
  });

  const calculate = inputLines => {
    let scope = { ...variables };
    let total = '';
    const ret = inputLines.map(input => {
      try {
        // @todo Review https://mathjs.org/docs/expressions/security.html.
        const resultRaw = evaluate(input, scope);
        // @todo Use 'fixed' notation and remove trailing zeros from integers.
        // See: https://github.com/josdejong/mathjs/issues/2079
        const resultFormatted =
          resultRaw !== undefined
            ? format(resultRaw, {
                lowerExp: -16,
                upperExp: 16,
              })
            : false;
        const result = resultFormatted !== 'function' ? resultFormatted : false;
        scope.prev = resultRaw;
        try {
          const expressionTotal =
            total !== '' ? `${result} + ${total}` : result;
          total = result ? evaluate(expressionTotal, scope) : '';
        } catch (e) {
          total = '';
        }
        scope.total = total;
        return {
          input,
          result: result.replace(/['"]+/g, ''),
          key: nanoid(),
        };
      } catch (e) {
        return {
          input,
          result: false,
          key: nanoid(),
        };
      }
    });
    setVariables(scope);
    return ret;
  };

  useEffect(() => {
    if (quill) {
      if (!addedFromUrl) {
        const inputText = getDataFromQueryString().input;
        const html = inputText
          .split('\n')
          .map(line => `<p>${line ? line : '<br>'}</p>`)
          .join('');
        quill.clipboard.dangerouslyPasteHTML(html);
        setAddedFromUrl(true);
        setLines(calculate(splitToLines(inputText)));
      }
      quill.on('text-change', () => {
        const inputText = quill.getText();
        setLines(calculate(splitToLines(inputText)));
        setDataInQueryString(inputText);
      });
      // Spellcheck can show errors on functions from Math.js, i.e. `sqrt`, so
      // it's better to disable it.
      quill.root.setAttribute('spellcheck', false);
    }
  }, [quill, addedFromUrl]);

  return (
    <Box m={2}>
      <Container maxWidth="md">
        <Paper variant="outlined" square>
          <div className={classes.wrapper}>
            <div className={classes.editor}>
              <div ref={quillRef} />
            </div>
            <div className={classes.results}>
              {lines.map(line => (
                <div key={line.key}>
                  {line.result ? <>{line.result}</> : <>&nbsp;</>}
                </div>
              ))}
            </div>
          </div>
        </Paper>
        <Typography
          className={classes.footer}
          variant="body2"
          color="textSecondary"
        >
          🔢 Numbers v{process.env.REACT_APP_VERSION} — © 2021 Relevant Bits,
          Inc.
        </Typography>
      </Container>
    </Box>
  );
};

export default App;
