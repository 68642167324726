import queryString from 'query-string';

export const encodeString = input =>
  window.btoa(window.unescape(encodeURIComponent(input)));

export const decodeString = input =>
  decodeURIComponent(window.escape(window.atob(input)));

export const encodeDataForQueryString = (input, settings = {}) =>
  queryString.stringify({
    i: encodeString(input),
    s: JSON.stringify(settings),
  });

export const decodeDataFromQueryString = string => {
  const parsed = queryString.parse(string);
  let settings = {};
  try {
    settings = JSON.parse(parsed.s);
  } catch (e) {}
  return {
    input: parsed.i ? decodeString(parsed.i) : '',
    settings,
  };
};

export const getDataFromQueryString = () =>
  decodeDataFromQueryString(window.location.hash);

export const setDataInQueryString = (input, settings = {}) => {
  window.location.hash = encodeDataForQueryString(input, settings);
};
